* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Gilroy", sans-serif;
}

@font-face {
  font-family: "Gilroy";
  src: url("/src/assets/fonts/Gilroy-Regular.ttf") format("ttf"),
    url("/src/assets/fonts/Gilroy-Regular.ttf") format("ttf");
}

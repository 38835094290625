@font-face {
  font-family: Gilroy;
  src: url("./assets/fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-ExtraBold.otf");
  src: url("./assets/fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Light.otf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Gilroy;
}

body {
  background: #151515;
  color: #fff;
}
.learnMore {
  font-size: 16px;
  font-weight: 600;
  color: #ffff;
  text-align: center;
}
.tracker {
  height: 22px;
  cursor: pointer;
}
.tracker img {
  width: 100% !important;
  height: 100% !important;
}
footer h1 {
  color: #ffff;
}
footer {
  padding: 15px 20px;
  /* border-top: 1px solid #7b7b7b; */
  height: 20vh;
  /* margin: 0 -25.5%; */
}
footer .wrapper {
  padding-top: 20px;
}
footer::before {
  content: "";
  height: 1px;
  background-color: #7b7b7b;
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
}
.trackerMessage {
  color: #ffff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 20px;
}
.helper {
  font-size: 12px;
  font-weight: 500;
  color: #7b7b7b;
  margin-top: 5px;
  cursor: pointer;
}
.learnMoreWrap {
  border-bottom: 1px solid #fff;
  width: fit-content;
  text-align: center;
  margin: 0 auto 15px !important;
  cursor: pointer;
}

.learnMoreWrap:hover {
  opacity: 0.8;
}
.downArrow {
  width: 12px !important;
  height: 12px !important;
}
@media screen and (min-width: 1000px) {
  .wrapper_container {
    max-width: 1000px;
    margin: 0 auto;
    height: 100vh;
    padding: 1.5rem;
  }
}

.error-message {
  color: red;
  font-size: 12px;
  text-align: center;
}

.frame-right-section {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    margin: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #383838;
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 5px;
    background: #494949;
  }
}
